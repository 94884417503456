<template>
    <grid-view 
        title="Типы лицензий"
        filter
        create
        create-title="Создать тип лицензии"
        url-create="/license_type/create"
        url-update="/license_type/update/:id"
        store-module="license_type"
        :headers="[
            { text: 'ID', value: 'id', width: 1 },
            { text: 'Активность', value: 'active', width: 1, sortable: false },
            { text: 'Код', value: 'code', width: 1 },
            { text: 'Название', value: 'name' },
            { text: '', value: 'edit', width: 1, sortable: false },
            { text: '', value: 'remove', width: 1, sortable: false }
        ]"
        :format="formatItems"
    >
        <!-- Filter -->
        <template v-slot:filter="{ filter }">
            <v-row>
                <v-col lg="6" sm="12">
                    <v-select
                        dense
                      v-model="filter.active"
                      :color="$const.color.primary"
                      :items="[{
                        text: 'Все',
                        value: null
                      },{
                        text: 'Нет',
                        value: false
                      },{
                        text: 'Да',
                        value: true
                      }]"
                      label="Активность"
                    ></v-select>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-text-field 
                        dense
                        v-model="filter.id"
                        clearable
                        autocomplete="new-email"
                        label="ID" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary"
                    ></v-text-field>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-text-field 
                        dense
                        v-model="filter.code"
                        clearable
                        autocomplete="new-email"
                        label="Код" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary"
                    ></v-text-field>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-text-field 
                        dense
                        v-model="filter.name"
                        clearable
                        autocomplete="new-email"
                        label="Название" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary"
                    ></v-text-field>
                </v-col>
            </v-row>
        </template>
        <!-- END Filter -->
    </grid-view>
</template>
<script>
import GridView from '@/components/crud/GridView.vue'
export default {
    name: 'UserIndex',
    components: { GridView }, 
    data() {
        return {}
    },
    methods: {
        formatItems(items) {
            return _.map(items, (item) => {
                // Active
                _.set(item, 'active', item.active === true ? 'Да' : 'Нет');

                return item;
            })
        } 
    }
}
</script>