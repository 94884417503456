var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid-view"},[_c('v-row',{attrs:{"align":"start","justify":"start","dark":""}},[_c('v-col',{staticClass:"d-flex flex-start align-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h6",domProps:{"innerHTML":_vm._s(_vm.title)}}),(_vm.filter)?_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}):_vm._e(),(_vm.filter)?_c('v-btn',{staticClass:"ml-auto white--text",attrs:{"outlined":"","small":"","color":_vm.$const.color.primary},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toggleFilter(true)}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-filter-variant ")]),_c('span',[_vm._v("Фильтр")])],1):_vm._e(),_c('v-spacer'),(_vm.create)?_c('v-btn',{staticClass:"ml-auto white--text",attrs:{"to":_vm.urlCreate,"color":_vm.$const.color.primary}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-plus ")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.createTitle)}})],1):_vm._e()],1)],1),_c('v-row',{attrs:{"align":"start","justify":"start","dark":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"dense":"","options":_vm.options,"server-items-length":_vm.total,"items-per-page":20,"footer-props":{
                    'disable-items-per-page': true,
                    'items-per-page-options': [],
                    'show-current-page': true,
                    'show-first-last-page': true
                },"calculate-widths":true,"headers":_vm.headers,"items":_vm.items,"item-key":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.downloadOfflineRegcode",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('download-offline-regcode', item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-download ")])],1)]}},{key:"item.download",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","href":item.download}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-download ")])],1)]}},{key:"item.downloadHandler",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return item.downloadHandler($event)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-download ")])],1)]}},{key:"item.edit",fn:function(ref){
                var item = ref.item;
return [(_vm.checkEditPermission(item))?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.edit(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e()]}},{key:"item.remove",fn:function(ref){
                var item = ref.item;
return [(_vm.checkDeletePermission(item))?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.remove(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}])}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.popup.remove),callback:function ($$v) {_vm.$set(_vm.popup, "remove", $$v)},expression:"popup.remove"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Вы действительно хотите удалить этот элемент?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","color":_vm.$const.color.primary},on:{"click":function($event){return _vm.remove(null)}}},[_vm._v("Отмена")]),_c('v-btn',{attrs:{"dark":"","color":_vm.$const.color.primary,"outlined":""},on:{"click":function($event){return _vm.remove(_vm.selectedOne)}}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)],1),_c('filter-view',{attrs:{"store-module":_vm.storeModule},on:{"close":function($event){return _vm.toggleFilter(false)},"filter":_vm.applyFilter},scopedSlots:_vm._u([{key:"default",fn:function(filterScope){return [_vm._t("filter",null,{"filter":filterScope.model})]}}],null,true),model:{value:(_vm.popup.filter),callback:function ($$v) {_vm.$set(_vm.popup, "filter", $$v)},expression:"popup.filter"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }